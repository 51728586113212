import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";

import "./EmailContactForm.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EmailContactForm = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [severity, setSeverity] = React.useState("success");

  const form = useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_packableapp",
        "template_packableapp",
        form.current,
        "yyHkPrD7diTZdJ_cG"
      )
      .then(
        (result) => {
          setSeverity("success");
          setMessage("You message has been sent");
          setOpen(true);
        },
        (error) => {
          setSeverity("error");
          setMessage("Failed to send your message");
          setOpen(true);
        }
      );
  };

  return (
    <div className="ContactUs-Container">
      <Box
        component="form"
        ref={form}
        className="ContactUs-EmailForm"
        autoComplete="off"
        onSubmit={sendEmail}
      >
        <TextField
          className="ContactUs-FormInput"
          required
          label="From Name"
          name="from_name"
          margin="normal"
        />
        <TextField
          className="ContactUs-FormInput"
          required
          label="From Email"
          name="from_email"
          margin="normal"
        />
        <TextField
          className="ContactUs-FormInput"
          required
          label="Message"
          name="message"
          multiline
          rows={4}
          margin="normal"
        />
        <Button className="ContactUs-Button" type="submit" variant="contained">
          Send
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmailContactForm;
