import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// framework
import { logDebug } from "../framework/loggers/Loggers";

// config parameters
import config from "../config/config.json";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
window.dataManagerURL = "undefined";
window.webBasedURL = "undefined";
window.logLevel = "undefined";

class Main extends Component {
  constructor(props) {
    super(props);

    window.logLevel = config.Application.LogLevel;
    logDebug("Initialized client with:");
    logDebug(`window.logLevel: ${window.logLevel}`);
    window.dataManagerURL = config.API.URL;
    logDebug(`window.dataManagerURL: ${window.dataManagerURL}`);
    window.webBasedURL = config.WEB.URL;
    logDebug(`window.webBasedURL: ${window.webBasedURL}`);
  }

  render() {
    logDebug(`Current URL: ${window.location.href}`);
    return (
      <div>
        <BrowserRouter basename={window.webBasedURL}>
          <Routes>
            <Route path="/contact" element={<Home tab={1} />} />
            <Route path="/privacy" element={<Home tab={2} />} />
            <Route exact path="/" element={<Home tab={0}/>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default Main;
