import React from "react";

import "./PrivacyPolicy.css";

export default function PrivacyPolicy(props) {

    const contact = () => {
        props.switchTab(1);
    }

  return (
    <div className="PrivacyPolicy-Container">
      <div className="PrivacyPolicy-SubHeaderItem">
        <p className="PrivacyPolicy-SubHeader">Privacy Policy</p>
      </div>
      <div className="PrivacyPolicy-TextItem">
        <p className="PrivacyPolicy-Text">
          We are not collecting any personal information. We believe such
          information is yours and yours alone. We do not store or transmit your
          personal details, nor do we include any advertising or analytics
          software that talks to third parties. All application data is stored
          on your device and is accessible only by Packable application
        </p>
      </div>
      <div className="PrivacyPolicy-SubHeaderItem">
        <p className="PrivacyPolicy-SubHeader">
          What Information Do We Collect?
        </p>
      </div>
      <div className="PrivacyPolicy-TextItem">
        <p className="PrivacyPolicy-Text">
          Packable application does not collect any personal information or
          connects to the internet. All data you enter when you create your destinations,
          boxes and items exist solely on your device and nowhere else.
        </p>
      </div>
      <div className="PrivacyPolicy-SubHeaderItem">
        <p className="PrivacyPolicy-SubHeader">Contact</p>
      </div>
      <div className="PrivacyPolicy-TextItem">
        <p className="PrivacyPolicy-Text">
          If you have any questions or concerns, please feel free to <a className="PrivacyPolicy-Link" onClick={contact}>contact us</a>
        </p>
      </div>
    </div>
  );
}
