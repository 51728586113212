import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// framework
import { logDebug } from "../framework/loggers/Loggers";

import "./HowToLinks.css";

export default function HowToLinks(props) {
  logDebug(`In HowToLinks with props: ${JSON.stringify(props)}`);

  const { videos, selectedVideo, setSelectedVideo } = props;

  const handleVideoSelection = (event, videoName) => {
    // find video
    let selectedVideo = videos.find((video) => video.name === videoName);
    if (selectedVideo) {
      setSelectedVideo(selectedVideo);
    }
  };

  return (
    <div>
      <Grid container className="HowToLinks-Container">
        {videos.map((video) => {
          return (
            <Grid 
              item className="HowToLinks-ButtonItem"
              key={video.name}
            >
              <Button
                className="HowToLinks-Button"
                variant={
                  selectedVideo
                    ? selectedVideo.name === video.name
                      ? "contained"
                      : "outlined"
                    : "outlined"
                }
                onClick={(event) => handleVideoSelection(event, video.name)}
              >
                {video.name}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
