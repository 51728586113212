export function logDebug(message) {
  if (window.logLevel === "DEBUG") {
    console.log(message);
  }
}

export function logInfo(message) {
  if (window.logLevel === "DEBUG" || window.logLevel === "INFO") {
    console.log(message);
  }
}

export function logError(message) {
  console.log(message);
}
