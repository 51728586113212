import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

import Main from "./components/Main.js";

export default function App(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "light" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </div>
  );
}




/*
import Home from './components/Home';
import HowToLinks from './components/HowToLinks';
//import logo from './logo.svg';
import logo from './img/screenshots/app_home.png'
import './App.css';

function App() {
  return (
    <Home />
  );
}

export default App;
*/