import React from "react";

import logoURL from "../img/logo.png";

import "./PageNotFound.css";

export default function PageNotFound() {
  return (
    <div className="NotFoundPage-Body">
      <div className="NotFoundPage-AppLogoItem">
        <img
          className="NotFoundPage-LogoImage"
          src={logoURL}
          alt="Packable Application"
        />
      </div>
      <div className="NotFoundPage-NotFoundMessageItem">
        <p className="NotFoundPage-NotFoundMessageText">
          Page not found
        </p>
      </div>
      <div className="NotFoundPage-HomeLinkItem">
          <a className="NotFoundPage-HomeLinkText" href="/">Packable Home</a>
      </div>
    </div>
  );
}
