import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// framework
import { logDebug } from "../framework/loggers/Loggers";

import HomeImage from "../img/screenshots/list.png";
import HowToLinks from "./HowToLinks";
import EmailContactForm from "./EmailContactForm";
import PrivacyPolicy from "./PrivacyPolicy";

import Prapor from "../img/uf.png";

import "./Home.css";

const videos = [
  {
    name: "Create New Destination",
    title: "Create New Destination",
    url: "https://www.youtube.com/embed/VS0F7FDvTiI",
  },
  {
    name: "Add Box",
    title: "Add Box",
    url: "https://www.youtube.com/embed/yokN20fXojc",
  },
];

export default function Home(props) {
  logDebug(`In Home with props: ${JSON.stringify(props)}`);

  const [selectedTab, setSelectedTab] = React.useState(props.tab ? props.tab : 0);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const switchTab = (newTab) => {
    setSelectedTab(newTab);
  }

  return (
    <Container className="Home-Page">
      <Grid container className="Home-Container">
        <Grid item className="Home-StandWithUkraine">
        <img src={Prapor} className="Home-StandWithUkraineImage" alt="Stand with Ukraine" />
        </Grid>
        <Grid item className="Home-TitleItem">
          <p className="Home-TitleText">Packable Application</p>
        </Grid>
        <Tabs
          className="Home-Tabs"
          value={selectedTab}
          onChange={handleTabChange}
          centered
        >
          <Tab label="How To" />
          <Tab label="Contact Us" />
          <Tab label="Privacy" />
        </Tabs>{" "}
        {selectedTab === 0 && (
          <Grid container className="Home-Items">
            <Grid item className="Home-HowToLinksItem">
              <HowToLinks
                videos={videos}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
              />
            </Grid>
            <Grid item className="Home-ImageItem">
              {!selectedVideo ? (
                <img src={HomeImage} className="Home-Image" alt="Packable" />
              ) : (
                <iframe
                  className="Home-Video"
                  src={selectedVideo.url}
                  title={selectedVideo.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </Grid>
          </Grid>
        )}
        {selectedTab === 1 && (
          <Grid container className="Home-Items">
            <EmailContactForm />
          </Grid>
        )}
        {selectedTab === 2 && (
          <Grid container className="Home-Items">
            <PrivacyPolicy switchTab={switchTab}/>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
